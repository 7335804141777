<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col id="desc">
				<p class="text-subtitle-1 text-left" id="scroll-to-0">あなたが司書課程を履修した動機について、もっとも近いものをお選びください。<span class="star">*</span><br/>図書館情報学の専門課程（筑波大学や慶應義塾大学など）を卒業された方は、課程を選んだ理由についてお答えください。<br/>【24項目】</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserScaleAnswerCard
					v-for="(item, index) in scaleitemorders"
					:key="'card-' + item.scaleitem.id"
					:scaleitem="item.scaleitem"
					:index="index + 1"
				></UserScaleAnswerCard>
				<v-card
					class="mx-auto mt-10"
					max-width="900"
					tile
					flat
					v-if="notAnsweredId.length"
				>
					<p class="mb-5">未回答の項目があります。</p>
					<v-chip
						v-for="item in notAnsweredId"
						:key="'not-answered-' + item"
						class="ma-1"
						color="error"
						outlined
						link
						@click="scrollTo('scroll-to-' + (item - 1))"
					>
						{{ item }}
					</v-chip>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapGetters, mapActions } from 'vuex'
import UserScaleAnswerCard from '@/components/demo/UserScaleAnswerCard'

export default {
	name: 'UserSurveyQ2',
	components: {
		UserScaleAnswerCard
	},
	data () {
		return {
			scaleitemorders: [
			{
				"id": 129,
				"order": 1,
				"scaleitem": {
					"id": 34,
					"text": "図書館で働きたかったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.129574+00:00",
					"modified_at": "2024-04-22T14:14:06.129586+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 130,
				"order": 2,
				"scaleitem": {
					"id": 35,
					"text": "司書になりたかったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.139670+00:00",
					"modified_at": "2024-04-22T14:14:06.139679+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 131,
				"order": 3,
				"scaleitem": {
					"id": 36,
					"text": "司書の仕事に興味があったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.142722+00:00",
					"modified_at": "2024-04-22T14:14:06.142729+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 132,
				"order": 4,
				"scaleitem": {
					"id": 37,
					"text": "司書を将来の職業の選択肢として考えていたから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.146232+00:00",
					"modified_at": "2024-04-22T14:14:06.146242+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 133,
				"order": 5,
				"scaleitem": {
					"id": 38,
					"text": "資格を取得することで安心したかったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.148919+00:00",
					"modified_at": "2024-04-22T14:14:06.148926+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 134,
				"order": 6,
				"scaleitem": {
					"id": 7,
					"text": "何か資格が欲しかったから",
					"desc": null,
					"created_at": "2023-09-28T10:15:05.822207+00:00",
					"modified_at": "2023-09-28T10:15:05.822213+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 135,
				"order": 7,
				"scaleitem": {
					"id": 8,
					"text": "文系の資格が欲しかったから",
					"desc": null,
					"created_at": "2023-09-28T10:15:05.824566+00:00",
					"modified_at": "2023-09-28T10:15:05.824571+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 136,
				"order": 8,
				"scaleitem": {
					"id": 9,
					"text": "多くの資格が欲しかったから",
					"desc": null,
					"created_at": "2023-09-28T10:15:05.826929+00:00",
					"modified_at": "2023-09-28T10:15:05.826934+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 137,
				"order": 9,
				"scaleitem": {
					"id": 39,
					"text": "尊敬している人の職業だったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.159391+00:00",
					"modified_at": "2024-04-22T14:14:06.159470+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 138,
				"order": 10,
				"scaleitem": {
					"id": 40,
					"text": "学校図書館にいた司書に憧れていたから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.163329+00:00",
					"modified_at": "2024-04-22T14:14:06.163337+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 139,
				"order": 11,
				"scaleitem": {
					"id": 41,
					"text": "公共図書館にいた司書に憧れていたから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.203806+00:00",
					"modified_at": "2024-04-22T14:14:06.203816+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 140,
				"order": 12,
				"scaleitem": {
					"id": 15,
					"text": "知人が司書資格を持っていたから",
					"desc": null,
					"created_at": "2023-09-28T10:15:05.844198+00:00",
					"modified_at": "2023-09-28T10:15:05.844203+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 141,
				"order": 13,
				"scaleitem": {
					"id": 42,
					"text": "本が好きだったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.211670+00:00",
					"modified_at": "2024-04-22T14:14:06.211678+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 142,
				"order": 14,
				"scaleitem": {
					"id": 43,
					"text": "読書が好きだったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.297699+00:00",
					"modified_at": "2024-04-22T14:14:06.297709+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 143,
				"order": 15,
				"scaleitem": {
					"id": 29,
					"text": "本や読書に関連する資格だから",
					"desc": null,
					"created_at": "2023-10-02T07:46:31.583225+00:00",
					"modified_at": "2023-10-02T07:46:31.583233+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 144,
				"order": 16,
				"scaleitem": {
					"id": 44,
					"text": "本のある場所/空間が好きだったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.384426+00:00",
					"modified_at": "2024-04-22T14:14:06.384461+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 145,
				"order": 17,
				"scaleitem": {
					"id": 21,
					"text": "司書資格が将来の仕事に役立つと思ったから",
					"desc": null,
					"created_at": "2023-09-28T10:15:05.860743+00:00",
					"modified_at": "2023-09-28T10:15:05.860748+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 146,
				"order": 18,
				"scaleitem": {
					"id": 22,
					"text": "司書課程の内容が将来の仕事に役立つと思ったから",
					"desc": null,
					"created_at": "2023-09-28T10:15:05.863022+00:00",
					"modified_at": "2023-09-28T10:15:05.863026+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 147,
				"order": 19,
				"scaleitem": {
					"id": 30,
					"text": "司書資格は就職活動をする時に役立つと思ったから",
					"desc": null,
					"created_at": "2023-10-02T07:46:31.593976+00:00",
					"modified_at": "2023-10-02T07:46:31.593984+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 148,
				"order": 20,
				"scaleitem": {
					"id": 31,
					"text": "司書課程の内容が将来の生活に役立つと思ったから",
					"desc": null,
					"created_at": "2023-10-02T07:46:31.596203+00:00",
					"modified_at": "2023-10-02T07:46:31.596207+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 149,
				"order": 21,
				"scaleitem": {
					"id": 45,
					"text": "書店で働きたかったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.649919+00:00",
					"modified_at": "2024-04-22T14:14:06.649931+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 150,
				"order": 22,
				"scaleitem": {
					"id": 46,
					"text": "出版社で働きたかったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.655531+00:00",
					"modified_at": "2024-04-22T14:14:06.655540+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 151,
				"order": 23,
				"scaleitem": {
					"id": 47,
					"text": "本の出版や販売に携わりたかったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.661184+00:00",
					"modified_at": "2024-04-22T14:14:06.661196+00:00"
				},
				"scale_id": 8
				},
				{
				"id": 152,
				"order": 24,
				"scaleitem": {
					"id": 48,
					"text": "図書館以外でも本に関わる仕事がしたかったから",
					"desc": null,
					"created_at": "2024-04-22T14:14:06.667490+00:00",
					"modified_at": "2024-04-22T14:14:06.667501+00:00"
				},
				"scale_id": 8
				}
			],
		}
	},
	computed: {
		...mapGetters({
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'setScaleItemNotAnswered',
			'setQ2',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		}
	}
}
</script>